import React from "react"
import SiteLayout from "../components/SiteLayout";

export default class LoginLink extends React.Component<any, any> {
    
    componentDidMount() {
        window.location.replace("https://itunes.apple.com/no/app/oss/id1355667096?mt=8");
    }

    render() {
        return (
            <div>
                Redirecting to <a href="https://itunes.apple.com/no/app/oss/id1355667096?mt=8" target="_blank" rel="noopener">https://itunes.apple.com/no/app/oss/id1355667096?mt=8</a>
            </div>
        )
    }
}
